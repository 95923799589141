@import "~@dewo/app/styles/theme.less";

.votingCard:global(.ant-list-item){
  display: flex;
  column-gap: 16;
  align-items: center;
  width: 100%;
  padding: 24px 0px;
  cursor: pointer;

  .votingCardTitle {
    margin: 0;
    transition: color 0.3s ease-in-out;
    font-weight: 500;
  }

  &:hover .votingCardTitle {
    color: @primary-color;
  }
}

.upvoteCard:global(.ant-card){
  background-color: @component-background;
  transition: background-color 0.3s ease-in-out;
  &.upvoted {
    background-color: @primary-color;
  }
}
