
.emojiPicker {
  /* #8570e4 */
  --rgb-accent: 133, 112, 228;
  --rgb-background: 29, 30, 38;
}

.buttonPopover {
  :global(.ant-popover-inner-content) {
    padding: unset;
  }
}
