@import "~@dewo/app/styles/theme.less";

.home {
  background: linear-gradient(180deg, #6f57ff 0%, #9a6aff 100%);
}

.sidebar {
  border-right: 2px solid @body-background;
  background-color: hsl(230, 14%, 8%);

  @media (max-width: @screen-sm) {
    position: absolute;
    inset: 0px;
    z-index: 10;
  }

  .item {
    padding: 0;
    width: 72px;
    height: 72px;
    display: grid;
    place-items: center;
    border-radius: @border-radius-base;

    transition: background 0.3s ease-in-out;
    position: relative;
    &:hover {
      background: @component-background;
      &::before {
        height: 20px;
        opacity: 1;
      }
    }

    &::before {
      content: "";
      width: @border-radius-base;
      height: 8px;
      opacity: 0;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      background-color: @text-color-secondary;
      position: absolute;
      left: 0;
      transition: all 0.3s ease-in-out;
    }

    &.active {
      &::before {
        background-color: @text-color;
        height: 48px;
        opacity: 1;
      }
    }

    
    :global {
      .ant-avatar {
        display: grid;
        place-items: center;
      }

      .ant-badge {
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }
  }
}

.redBadge:global(.ant-badge) :global(.ant-badge-count) {
  background: @red-base;
}

.navigationList {
  background-color: hsl(230, 14%, 11%);
  width: 220px;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;

  > * {
    transition: opacity 0.3s ease-in-out;
  }
  .minimized {
    pointer-events: none;
    opacity: 0;
  }
}
