@import "~@dewo/app/styles/theme.less";

.tagSuffix {
  display: grid;
  align-items: center;
  justify-content: center;
  border-top-right-radius: @border-radius-base;
  border-bottom-right-radius: @border-radius-base;
  padding: 0 8px;
  margin: -3px -8px -3px 5px;
}

.tagContent {
  display: flex;
}
