@import "~antd/dist/antd.less";
@import "./theme.less";

html {
  background: @body-background;
  color-scheme: dark;
}

body,
.ant-layout {
  background: transparent;
}

textarea {
  resize: none;
}

iframe {
  border: 1px solid @border-color-base;
  background: @component-background;
}

.ant-menu {
  background: transparent;
  border: none;
}

.ant-table-thead th.ant-table-column-has-sorters:hover,
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: @component-background;
}

.ant-modal .ant-table tbody > tr.ant-table-row:hover > td {
  background: transparent;
}

.ant-modal .ant-card {
  background: @component-background;
}

.ant-table-thead th.ant-table-column-sort {
  background: unset;
}

.ant-table-filter-column,
.ant-table-column-sorters {
  justify-content: unset;
  .ant-table-column-title {
    flex: unset;
  }
}

.ant-tabs-content-holder {
  overflow: auto;
}

.ant-modal-title {
  text-align: center;
}

.ant-card.ant-card-small {
  .ant-card-body {
    padding-top: 4px;
    padding-bottom: 4px;
    line-height: normal;
  }

  .ant-card-actions > li {
    margin: 4px 0;
  }

  .ant-card-actions {
    border-top: 0;
    background: hsl(230, 14%, 22%);
  }
}

.ant-btn {
  font-weight: 500;
}

.ant-btn-sm {
  font-size: 13px;
}

.ant-btn.ant-btn-ghost {
  background: @btn-text-hover-bg;
}

.ant-btn.ant-btn-default {
  border: none;
  &:active,
  &:hover,
  &:focus {
    color: unset;
  }
}

.ant-btn.hover\:ant-btn-dangerous {
  &:hover,
  &:active,
  &:focus {
    background: unset;
    color: @error-color;
    border-color: @error-color;
  }
}

.ant-form-item {
  margin-bottom: 12px;
}

.ant-typography-edit {
  color: @text-color-secondary;
}

// .ant-alert {
//   padding: 4px 12px;
// }

.ant-typography-h1 {
  .typography-title-1();
  margin: 0;
}
.ant-typography-h2 {
  .typography-title-2();
  margin: 0;
}
.ant-typography-h3 {
  .typography-title-3();
  margin: 0;
}
.ant-typography-h4 {
  .typography-title-4();
  margin: 0;
}
.ant-typography-h5 {
  .typography-title-5();
  margin: 0;
}

// .ant-typography-caption, .ant-btn-sm {
.ant-typography-caption {
  font-size: 11px;
}

.ant-select .ant-select-disabled .ant-select-selector {
  background: unset;
  border: unset;
  padding: 0;
}

.ant-page-header-heading-left,
.ant-page-header-heading-tags {
  flex: 1;
}

.ant-page-header-heading .ant-avatar {
  margin-right: unset;
}

.dewo-label {
  color: @text-color-secondary;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
}

.ant-form-item-label,
.ant-form-vertical .ant-form-item-label {
  padding-bottom: 2px;

  &,
  & > label {
    color: @label-color;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    height: unset;
    letter-spacing: 0.5px;

    &::after {
      display: none;
    }
  }
}

.dewo-field {
  &.dewo-field-focus-border:not(:focus):not(:hover):not(.ant-input-focused) {
    border-color: transparent;
  }

  &:disabled {
    background: unset;
    border: unset;
    cursor: unset;
    color: unset;

    &:not(.dewo-field-focus-border) {
      padding: 0;
      margin: 0;
    }
  }
}

.pointer-cursor {
  cursor: pointer;
}

// prevent task board overflow
.ant-tabs-content {
  height: 100%;
  max-height: 100%;
}

.ant-tabs-tabpane {
  overflow-y: auto;
}

.dewo-task-board {
  flex: 1;
  height: 100%;
  overflow-x: auto;
  max-height: 100vh;
  background: @body-background;

  padding: 12px;
  @media (min-width: @screen-sm) {
    padding: 32px;
    padding-bottom: 16px;
  }

  // TODO(fant): rework these hacks
  & > .ant-space {
    display: flex;
    max-height: 100%;

    & > .ant-space-item {
      display: flex;
      flex-direction: column;
      min-height: 0;
      height: 100%;
      max-height: 100%;

      & > .ant-card {
        display: flex;
        flex-direction: column;
        min-height: 0;
        height: 100%;
        max-height: 100%;

        & > .ant-card-head {
          flex-shrink: 0;
        }

        & > .ant-card-body {
          height: 100%;
          overflow: hidden;

          & > div,
          & > div > div {
            height: 100%;
          }
        }
      }
    }
  }

  .dewo-task-board-column {
    background: @body-background-secondary;
    height: 100vh;
    .dewo-task-board-column-section-title {
      padding-left: 8px;
      padding-right: 8px;

      .dewo-task-board-column-section-options {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }

      &:hover .dewo-task-board-column-section-options,
      .dewo-task-board-column-section-options:focus {
        visibility: visible;
        opacity: 1;
      }
    }

    .ant-card-head {
      border-bottom-color: @border-color-split;
      border-bottom-width: 1px;
    }
  }
}

.dewo-landing-page-main {
  div:last-child {
    max-height: 100%;
  }
}
.dewo-task-board-column.ant-card-small {
  & > .ant-card-head > .ant-card-head-wrapper {
    .ant-card-extra {
      padding: 0;
    }
    .ant-card-head-title {
      padding: 12px 0;
    }
  }

  & > .ant-card-head {
    margin-bottom: 0;
  }

  & > .ant-card-body {
    overflow-y: auto;
    padding-top: 0;
  }
}
.ant-card.dewo-project-card {
  height: 100%;
  cursor: pointer;

  & .ant-card-body {
    height: 100%;
    display: grid;
    place-items: center;
  }
}

.ant-list-item-action {
  margin-left: 0;
}

.ant-list-item-meta {
  align-items: center;
}

.ant-list-item-meta-title {
  margin-bottom: 0;
}

.ant-card.dewo-card-highlighted {
  background-color: @primary-color-25;
  border: 2px solid @primary-color;
  .ant-card {
    background-color: fade(@component-background, 60%);
  }
}

.hover\:component-highlight {
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background: @component-background-highlighted;
  }
}

.hover\:cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

.dewo-divider-right {
  border-right: 1px solid @divider-color;
}

.ant-empty-image img {
  height: 100%;
}

.ant-popover-message-title {
  max-width: 200px;
}

.ant-tabs-tab .anticon {
  margin-right: 6px;
}

.ant-tabs-tab-active {
  font-weight: 500;
}

.ant-tabs-tab:not(.ant-tabs-tab-active) .anticon {
  color: @text-color-secondary;
}

.max-w-xs {
  max-width: @screen-xs;
}
.max-w-sm {
  max-width: @screen-sm;
}
.max-w-md {
  max-width: @screen-md;
}
.max-w-lg {
  max-width: @screen-lg;
}
.max-w-xl {
  max-width: @screen-xl;
}
.max-w-xxl {
  max-width: @screen-xxl;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.flex {
  display: flex;
}
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.pl-1 {
  padding-left: 4px;
}
.pr-1 {
  padding-right: 4px;
}
.pl-2 {
  padding-left: 16px;
}
.pr-2 {
  padding-right: 16px;
}

.mb-2 {
  margin-bottom: 16px;
}

.mb-3 {
  margin-bottom: 24px;
}

.mb-7 {
  margin-bottom: 56px;
}

.bg-primary {
  background: @primary-color;
}

.bg-component {
  background: @component-background;
}

.bg-body {
  background: @body-background;
}

.bg-body-secondary {
  background-color: @body-background-secondary;
}

#__next,
.ant-layout {
  height: 100%;
}
.show-sm {
  display: none;
  @media (min-width: @screen-sm) {
    display: block;
  }
}
.show-xs {
  display: none;
  @media (max-width: @screen-sm) {
    display: block;
  }
}
@media (max-width: @screen-sm) {
  .hide-xs {
    display: none;
  }
}
@media (max-width: @screen-md) {
  .hide-sm {
    display: none;
  }
}
.text-overflow-ellipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-secondary {
  color: @text-color-secondary;
}

.text-primary {
  color: @primary-color;
}
.font-bold {
  font-weight: 600;
}

.font-semibold {
  font-weight: 500;
}

.dewo-md-tabs {
  .ant-tabs-nav-wrap {
    max-width: 120px;
    z-index: 1;
  }
  .ant-tabs-tabpane {
    overflow-y: unset;
  }
}

.dewo-md-editor-footer {
  border: 1px solid @border-color-base;
  border-top: none;
  border-bottom-left-radius: @border-radius-base;
  border-bottom-right-radius: @border-radius-base;

  padding: 4px;
  padding-left: 12px;
}

.ant-upload.ant-upload-drag.dewo-md-upload {
  background-color: unset;

  &.ant-upload.ant-upload-drag {
    border: none;
  }
  &.ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }
}

.dewo-md-upload.ant-upload.ant-upload-drag .ant-upload-btn,
.dewo-md-upload.ant-upload.ant-upload-drag
  .ant-upload-btn
  .ant-upload-drag-container {
  display: block;
}

.dewo-lp-feature-tabs {
  .ant-tabs-nav:before {
    display: none;
  }

  .highlight {
    border: 1px solid @primary-color;
    border-radius: @border-radius-base * 2;
    box-shadow: 0 0 10px 0 @primary-color;
  }
}

.dewo-settings-tabs > .ant-card {
  background-color: @body-background-secondary;

  .ant-tabs-nav-list {
    display: flex;
    flex-direction: column;
    min-width: 192px;

    .ant-tabs-tab-active {
      background: @component-background;

      .ant-tabs-tab-btn {
        color: @text-color;
      }
    }
  }

  .ant-tabs-nav-more {
    display: none;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 0;
  }

  .ant-tabs-tab {
    padding-right: 12px;
    padding-left: 12px;
  }
}

.ant-badge .ant-badge-count {
  background: @component-background;
}

.dewo-task-reaction {
  padding-left: 4px;
  border-radius: @border-radius-base;
  border: 1px solid @border-color-base;

  transition: background, border 0.3s ease-in-out;

  &.dewo-task-reaction-selected {
    border: 1px solid @primary-color;
    background: @primary-color-25;
  }

  &.dewo-task-reaction-selectable {
    cursor: pointer;
  }

  .ant-badge .ant-badge-count {
    background: transparent;
    box-shadow: none;
    font-weight: 600;
  }

  .emojione {
    display: grid;
    place-items: center;
  }
}

.ant-tabs-top .ant-tabs-nav {
  margin: 0;
}

.dewo-tabs {
  .ant-tabs-content {
    background-color: @body-background;
  }

  & > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
    padding-left: 12px;
    padding-right: 12px;
  }

  .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
  }
}

.ant-tabs-left > .ant-tabs-content-holder {
  border: none;
}

.dewo-table-xs {
  margin-left: -2px;

  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 2px 2px;
    border-bottom: none;
  }
}

.dewo-btn-highlight {
  flex: 1;
  padding: 4px 8px;
  margin: 0 -8px;
  height: unset;
  display: flex;
  align-items: center;
}

.ant-dropdown-trigger:not([disabled]):hover {
  cursor: pointer;
}

.dewo-discovery-table {
  .ant-table {
    background: transparent;
  }

  table {
    border-collapse: separate;
    border-spacing: 0 16px;
    margin-top: -16px;
  }

  .ant-table-row {
    background: @primary-color-15;
  }

  tr > th {
    border: none;
  }

  tr > td {
    border-top: 2px solid @primary-color;
    border-bottom: 2px solid @primary-color;

    &:first-child {
      border-left: 2px solid @primary-color;
      border-top-left-radius: @border-radius-base * 2;
      border-bottom-left-radius: @border-radius-base * 2;
    }

    &:last-child {
      border-right: 2px solid @primary-color;
      border-top-right-radius: @border-radius-base * 2;
      border-bottom-right-radius: @border-radius-base * 2;
    }
  }
}

.dewo-card-table {
  .ant-table {
    background: transparent;
  }

  table {
    border-collapse: separate;
    border-spacing: 0 8px;
  }

  .ant-table-row {
    background: @body-background-secondary;
  }

  tr > th {
    border: none;
  }

  tr > td {
    border: none;

    &:first-child {
      border-top-left-radius: @border-radius-base;
      border-bottom-left-radius: @border-radius-base;
    }

    &:last-child {
      border-top-right-radius: @border-radius-base;
      border-bottom-right-radius: @border-radius-base;
    }
  }
}

.ant-modal-close {
  padding-right: 12px;
  .ant-modal-close-x {
    width: 32px;
  }
}

.dewo-task-options-button {
  position: absolute;
  top: 12px;
  right: 32px + 12px;
  width: 32px;
  height: 32px;
  padding: 0;
  color: @text-color-secondary;
}

.dewo-danger-zone {
  border: solid 1px @red-6;
  border-radius: 4px;
}

.dewo-tag-select-option {
  &.ant-select-item-option-selected {
    background: unset;
  }

  &.ant-select-item-option-active {
    background: @component-background;
  }

  .dewo-tag-select-option-button {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
  &:hover {
    .dewo-tag-select-option-button {
      display: block;
    }
    .ant-select-item-option-state {
      display: none;
    }
  }
}

.dewo-twitter-btn {
  &:hover,
  &:focus,
  &:active {
    color: @twitter-color;
    border-color: @twitter-color;
  }
}

.ant-tag {
  border: none;
  display: inline-flex;
  align-items: center;
}

.ant-avatar {
  background-color: fade(@white, 13%);

  .make-color-classes(@i: length(@preset-colors)) when (@i > 0) {
    .make-color-classes(@i - 1);
    @color: extract(@preset-colors, @i);
    @colorValue: "@{color}-6";
    &-@{color} {
      color: @@colorValue;
      background: fade(@@colorValue, 10%);
      border: 1px solid fade(@@colorValue, 40%);
    }
  }
  .make-color-classes();

  &-white {
    color: @white;
    background: fade(@white, 20%);
    // border: 2px solid fade(@white, 20%);
    border: 2px solid @white;
  }
}

.ant-avatar-group {
  align-items: center;
}

.ant-avatar-group > *:not(:first-child) {
  margin-left: -8px;
}

.ant-breadcrumb {
  > span:last-child a {
    color: @text-color-secondary;
  }

  a:hover,
  & > span:last-child a:hover {
    color: @text-color;
  }

  li .ant-breadcrumb-link {
    display: inline-flex;
    align-items: center;
  }
}

.dewo-suggestion-card {
  &.dewo-suggestion-upvoted {
    background-color: @primary-color-50;
  }
  transition: background-color 0.3s ease-in-out;
}

.dewo-simple-button.ant-btn.ant-btn-default {
  background: none;
  border: 1px solid @white;
}

.make-color-classes(@i: length(@preset-colors)) when (@i > 0) {
  .make-color-classes(@i - 1);
  @color: extract(@preset-colors, @i);
  @colorValue: "@{color}-6";
  .text-color-@{color} {
    color: @@colorValue;
  }
}
.make-color-classes();

.overflow-y-hidden {
  overflow-y: hidden;
}

.ProseMirror {
  .token.cdata,
  .token.comment,
  .token.doctype,
  .token.prolog,
  .code-block[data-language] .token.block-comment {
    color: #999;
  }
  .code-block[data-language] .token.punctuation {
    color: #ccc;
  }
  .code-block[data-language] .token.attr-name,
  .code-block[data-language] .token.deleted,
  .code-block[data-language] .token.namespace,
  .code-block[data-language] .token.tag {
    color: #e2777a;
  }
  .code-block[data-language] .token.function-name {
    color: #6196cc;
  }
  .code-block[data-language] .token.boolean,
  .code-block[data-language] .token.function,
  .code-block[data-language] .token.number {
    color: #f08d49;
  }
  .code-block[data-language] .token.class-name,
  .code-block[data-language] .token.constant,
  .code-block[data-language] .token.property,
  .code-block[data-language] .token.symbol {
    color: #f8c555;
  }
  .code-block[data-language] .token.atrule,
  .code-block[data-language] .token.builtin,
  .code-block[data-language] .token.important,
  .code-block[data-language] .token.keyword,
  .code-block[data-language] .token.selector {
    color: #cc99cd;
  }
  .code-block[data-language] .token.attr-value,
  .code-block[data-language] .token.char,
  .code-block[data-language] .token.regex,
  .code-block[data-language] .token.string,
  .code-block[data-language] .token.variable {
    color: #7ec699;
  }
  .code-block[data-language] .token.entity,
  .code-block[data-language] .token.operator,
  .code-block[data-language] .token.url {
    color: #67cdcc;
  }
  .code-block[data-language] .token.bold,
  .code-block[data-language] .token.important {
    font-weight: 700;
  }
  .code-block[data-language] .token.italic {
    font-style: italic;
  }
  .code-block[data-language] .token.entity {
    cursor: help;
  }
  .code-block[data-language] .token.inserted {
    color: green;
  }
  .code-block[data-language] .token.string-literal.interpolation.content {
    color: #7ec699;
  }
  .code-block[data-language]
    .token.string-literal.interpolation.delimiter.punctuation {
    color: #e2777a;
  }
  .heading-actions {
    display: none !important;
  }
}

.ReactVirtualized__Grid__innerScrollContainer {
  min-height: 100%;
}

.dewo-layout-padding-vertical {
  padding-left: 12px;
  padding-right: 12px;
  @media (min-width: @screen-sm) {
    padding-left: 32px;
    padding-right: 32px;
  }
}

div.ProseMirror[role="textbox"][contenteditable="true"] {
  min-height: 50px;
}

.ant-carousel {
  .slick-slider {
    padding-bottom: 20px;
  }

  .slick-dots-bottom {
    bottom: 0;
    margin-bottom: 0;
  }

  .slick-dots {
    li {
      margin: 0 4px;
      background: @component-background;

      &,
      &.slick-active,
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }

      &.slick-active {
        background: @text-color;

        button {
          background: unset;
        }
      }
    }
  }
}
