@import "~@dewo/app/styles/theme.less";

.card:global(.ant-card) {
  margin-bottom: 2px;
  display: inline-block;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;

  :global(.ant-card-body) {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  &:hover {
    background-color: @component-background;
  }
}
