@import "~@dewo/app/styles/theme.less";

.listItem {
  .archiveButton {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    .archiveButton {
      opacity: 1;
    }
  }
}

.breadcrumb:global(.ant-breadcrumb) {
  ol li:last-child {
    &, a {
      color: @text-color-secondary;
    }
  }
}

.notificationMessage span[role="img"] {
  margin-right: 8px;
}

.dropdownButtonBadge:global(.ant-badge) {
  position: absolute;
  top: 0;
  right: 0;

  :global(.ant-badge-count) {
    background: @red-base;
    padding: 0 4px;
  }
}

.popoverButtonPopover {
  :global(.ant-popover-title) {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  :global(.ant-popover-inner-content) {
    width: 400px;
    height: 480px;
    overflow-y: auto;
  }
}
