@import "~@dewo/app/styles/theme.less";

.topRowTags {
  margin-bottom: 24px;
  font-weight: 500;
  :global(.ant-tag) {
    padding: 2px 8px;
  }
}

.grower {
  flex: 1;
  display: flex;
  align-items: end;
}

.form {
  :global {
    .ant-select.ant-select-disabled.ant-select:not(.ant-select-customize-input) {
      .ant-select-selector {
        background: unset;
        border: none;
        padding: 0;
        color: @text-color;
      }
    
      .ant-select-arrow {
        display: none;
      }
    
      .ant-select-selection-placeholder {
        left: 0;
      }
    }
    
    .ant-input-number.ant-input-number-disabled,
    .ant-input-number-affix-wrapper.ant-input-number-affix-wrapper-disabled {
      background: unset;
      border: none;
      color: @text-color;
      padding: 0;
    
      input {
        padding: 0;
      }
    }
    
    .ant-picker.ant-picker-disabled {
      background: unset;
      border: none;
      padding: 0;
    
      .ant-picker-input input {
        color: @text-color;
      }
    
      .ant-picker-suffix {
        display: none;
      }
    }
  }
}
