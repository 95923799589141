.sider {
  overflow: hidden;
  .siderContent {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    &.collapsed {
      opacity: 0;
    }
  }
}
