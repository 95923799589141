.collapse {
  background: none;
  > :global(.ant-collapse-item) {
    border: none;
    > :global(.ant-collapse-header) {
      display: none;
    }
    > :global(.ant-collapse-content) > :global(.ant-collapse-content-box) {
      padding: 0;
    }
  }
}
