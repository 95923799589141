@import "~@dewo/app/styles/theme.less";

.tile {
  height: unset;
  padding: 16px 24px;
  white-space: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.useCaseTile {
  border: 2px solid transparent;
  cursor: pointer;
  transition: all .3s ease-in-out;

  &.useCaseTileActive {
    border-color: @primary-color;
  }
}

:global(.ant-card).usageReasonRow {
  background: transparent;
  transition: background .3s ease-in-out;

  &.usageReasonRowChecked {
    background: @component-background;
  }
}
