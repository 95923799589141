@import "~@dewo/app/styles/theme.less";

:global(.dewo-card-highlighted) .timeline:global(.ant-timeline) {
  :global(.ant-timeline-item-head) {
    background-color: inherit;
  }
}

.timeline:global(.ant-timeline) {
  :global {
    .ant-timeline-item {
      padding-bottom: 16px;
    }

    .ant-timeline-item-content {
      top: unset;
      margin-left: 24px + 12px;

      .ant-card-body {
        padding: 8px 16px;
      }

      .ant-input,
      .ant-input:focus {
        border: unset;
      }
    }

    .ant-timeline-item-head {
      top: 10px;
      left: 8px;
    }

    .ant-timeline-item-head-custom {
      top: unset;
      left: unset;
      transform: unset;
    }

    .ant-timeline-item-tail {
      // (avatar width + avatar padding - line width) / 2
      left: 12px;
      top: 0;
      height: 100%;
    }
  }
}
