@import "~@dewo/app/styles/theme.less";

.container {
  position: relative;
}

.viewMoreContainer {
  flex-grow: 1;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  &.fadeOut::after {
    pointer-events: none;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 70%,
      @body-background 100%
    );
  }

  &.open {
    overflow: unset;
    &::after {
      background: none;
    }
  }
}

:global(.ant-modal-content) .viewMoreContainer.fadeOut:not(.open)::after {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 70%,
    @popover-background 100%
  );
}
