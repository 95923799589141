@import "~@dewo/app/styles/theme.less";

.card {
  transition: background 0.3s ease-in-out;
  border-radius: @border-radius-base;
  padding: 8px;
  margin: 0 -8px;
}
.activeCard {
  &.card {
    background: fade(@white, 4%);
  }
}
