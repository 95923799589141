@import "./theme.less";

html[platform^=Win], html[platform^=Linux] {
  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    transition: background-color 0.3s ease-in-out;
    background-color: @component-background;
    border-radius: 5px;
    border: 2px solid transparent;
    background-clip: content-box;
  }
  
  *:hover::-webkit-scrollbar-thumb {
    background: @component-background-highlighted;
  }
}

