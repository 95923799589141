@import "~@dewo/app/styles/theme.less";

.menu:global(.ant-menu-inline) {
  :global {
    .ant-menu-item {
      height: 32px;
      line-height: 32px;
      width: unset;
      padding: 0 2px 0 8px !important; /* antd's Menu w mode="inline" adds paddingLeft: 24 */
      margin: 0 12px 2px 12px;
      font-weight: 500;
      border-radius: @border-radius-base;

      transition: background-color 0.3s ease-in-out;

      a, &:hover, &.ant-menu-item-active, &.ant-menu-item-selected {
        color: unset;
        background-color: unset;
      }

      &:hover, &.ant-menu-item-active {
        background-color: @component-background;
      }

      &:after {
        display: none;
      }
    }

    .ant-menu-item-icon {
      color: @text-color-secondary;
    }

    .ant-menu-item-group-title {
      color: @text-color-secondary;
      padding: 0 8px 0 16px !important; /* antd's Menu w mode="inline" adds paddingLeft: 24 */
      margin: 24px 0 0 0;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 11px;
      font-weight: 600;

      &:active {
        background: unset;
      }
    }
  }
}

.redBadge:global(.ant-badge) :global(.ant-badge-count) {
  background: @red-base;
}

.createTaskButtonContainer {
  padding: 0 8px 24px 8px; 
}

.tree:global(.ant-tree) {
  margin-right: 8px;

  :global {
    @media (min-width: @screen-sm) {
      .ant-tree-list:hover {
        .ant-tree-switcher {
          opacity: 0;
        }
        &:hover .ant-tree-switcher {
          opacity: 1;
        }
      }
    }

    .ant-tree-switcher {
      width: 12px;
      color: @text-color-secondary;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: @text-color;
      }
    }

    .ant-tree-switcher, .ant-tree-node-content-wrapper {
      // height: 32px;
      line-height: 32px;
    }

    .ant-tree-treenode:not(.ant-tree-treenode-disabled) .ant-tree-node-content-wrapper {
      &.ant-tree-node-selected, &:hover {
        background-color: @component-background;
      }
    }

    .ant-tree-node-content-wrapper {
      padding: 0 4px 0 8px;
      white-space: nowrap;
      min-width: 0;

      .ant-tree-title {
        display: flex;

        .ant-typography {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .visibleOnMenuItemHover {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .visibleOnMenuItemHover:focus,
  :global(.ant-tree-node-content-wrapper):hover .visibleOnMenuItemHover,
  :global(.ant-tree-node-selected) .visibleOnMenuItemHover {
    opacity: 1;
  }
}
